import { createTheme } from '@mui/material/styles';

const baseTheme = createTheme({
  palette: {
    primary: {
      main: '#6c6c6c',
      dark: '#0d3964',
      light: 'rgba(25,118,210,0.49)',
    },
    secondary: {
      main: '#3C3C3B',
      light: '#F8F8F8',
    },
    info: {
      main: '#55c57a',
    },
    text: {
      primary: '#3C3C3B',
      secondary: '#3C3C3B',
    },
    button: {
      main: '#0679C3',
      contact: '#7AB828',
    },
  },
  typography: {
    fontFamily: '"Arial", "Roboto", "Helvetica", sans-serif',
    button: {
      lineHeight: 1.5,
      letterSpacing: 0,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1920,
    },
  },
  fonts: {
    base: {
      lineHeight: 1.5,
    },
    componentTitle: {
      fontSize: '18px',
    },
    sidebarLink: {
      fontSize: '12px',
    },
    button: {
      fontSize: '1rem',
    },
  },
  gradients: {
    primary: {
      linear: `linear-gradient(
        to bottom right,
        rgba(74, 74, 74, 0.85),
        rgba(222, 222, 222, 0.85));
      ) !important`,
    }

  }
});

const theme = createTheme(baseTheme, {
  components: {
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          backgroundColor: baseTheme.palette.common.white,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: baseTheme.fonts.button.fontSize,
          backgroundColor: baseTheme.palette.common.white,
          borderRadius: '10px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: baseTheme.fonts.button.fontSize,
          borderRadius: '10px',
          padding: '8px 16px',
        },
        outlinedPrimary: {
          backgroundColor: baseTheme.palette.common.white,
        },
        outlinedSecondary: {
          borderColor: baseTheme.palette.secondary.main,
        },
        containedPrimary: {
          borderColor: baseTheme.palette.primary.main,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          backgroundColor: baseTheme.palette.common.white,
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          backgroundColor: baseTheme.palette.common.white,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: baseTheme.palette.text.primary,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          outline: `1px solid transparent`,
          '&.MuiTableRow-hover:hover': {
            outline: `1px solid ${baseTheme.palette.primary.main}`,
            cursor: 'pointer',
          },
        },
      },
    },
  },
});

export default theme;
