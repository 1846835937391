import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import ConstructionIcon from '@mui/icons-material/Construction';
import { Link } from 'react-router-dom';

const NavBarStyles = {
    '.navbarTop': {
        lineHeight: 1,
        backgroundColor: 'transparent !important',
        boxShadow: 'none !important',
        // backgroundColor: 'rgba(40, 180, 135, 0.35) !important'
    },
    '.navbarBottom': {
        lineHeight: 1,
        backgroundImage: (theme) => theme.gradients.primary.linear,
    }
};

const pages = [{
    title: 'Realizacje',
    to: '/gallery',
},
// {
//     title: 'Cennik',
//     to: '/pricing',
// },
{
    title: 'Usługi',
    to: '/services'
},
{
    title: 'Kontakt',
    to: '/contact',
},
];

const ResponsiveAppBar = (props) => {
    const navbarTransitionHeight = props.transitionHeight;
    const disableNavbarTransparency = props.disableTransparency ? props.disableTransparency : false;
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [navbarVersion, setNavbarVersion] = React.useState(1);

    React.useEffect(() => {
        if (disableNavbarTransparency) {
            setNavbarVersion(2);
        } else {
            setNavbarVersion(1);
        }
        window.addEventListener('scroll', () => {
            if (document.documentElement.scrollTop <= navbarTransitionHeight * window.innerHeight - 50 && !disableNavbarTransparency) {
                setNavbarVersion(1);
            }
            else {
                setNavbarVersion(2);
            }
        })
    }, [disableNavbarTransparency, navbarTransitionHeight]);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <Box sx={NavBarStyles}>
            <AppBar position="fixed" className={navbarVersion === 1 ? 'navbarTop' : 'navbarBottom'} >
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{ mr: 2, display: { xs: 'none', md: 'flex' }, fontSize: '1.5rem', fontWeight: 300, alignItems: 'center' }}
                        >
                            <IconButton component={Link} to={'/'}>
                                <ConstructionIcon sx={{ fontSize: 60, color: 'white' }} />
                            </IconButton>
                        </Typography>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon sx={{ fontSize: 45 }} />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {pages.map((page) => (
                                    <MenuItem key={page.title} onClick={handleCloseNavMenu}>
                                        <Typography component={Link} to={page.to} textAlign="center" sx={{ fontSize: '1.5rem', fontWeight: 300 }}>{page.title}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                        <Typography
                            component={Link} to={'/'}
                            variant="h6"
                            noWrap
                            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none', fontSize: '1.5rem', fontWeight: 300 } }}
                        >
                            <IconButton component={Link} to={'/'} sx={{ marginLeft: 'auto' }}>
                                <ConstructionIcon sx={{ fontSize: 45, marginLeft: 'auto', color: 'white' }} />
                            </IconButton>
                        </Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>
                            {pages.map((page) => (
                                <Button
                                    component={Link}
                                    to={page.to}
                                    key={page.title}
                                    onClick={handleCloseNavMenu}
                                    sx={{ my: 2, mx: 4, color: 'white', display: 'block', fontSize: '1.75rem', fontWeight: 300 }}
                                >
                                    {page.title}
                                </Button>
                            ))}
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar >
        </Box>
    );
};
export default ResponsiveAppBar;