const generateStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
        stars.push(
            <svg className={`reviews__star reviews__star--${rating >= i ? 'active' : 'inactive'}`} >
                <use href='/img/icons.svg#icon-star' />
            </svg>
        )
    }
    return stars;
}

const ReviewCard = (props) => {
    const username = props.username;
    const opinion = props.opinion;
    const avatar = props.avatar;
    const rating = props.rating;
    return (
        <>
            <div className="reviews__card">
                <div className="reviews__avatar">
                    {/* <img className="reviews__avatar-img" src={`/img/users/${avatar}.jpg`} alt="" /> */}
                    <h6 className="reviews__user">{username}</h6>
                </div>
                <p className="reviews__text">{opinion}</p>
                <div className="reviews__rating">
                    {generateStars(rating)}
                </div>
            </div>
        </>
    )
}
export default ReviewCard;