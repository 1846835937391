import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import NavBar from '../components/NavBar';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: 14,
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const PricingPageStyles = {
    '.title': {
        marginTop: {
            xs: '20px',
            lg: '30px',
            xl: 0,
        },
        marginBottom: '40px'
    }
}

function createData(name, price, description) {
    return { name, price, description };
}

const rows = [
    createData('Malowanie ścian', 'od 200 zł',
        'Profesjonalne malowanie ścian według ustalonego kodu koloru'),

    createData('Wykończenie ścian', 'od 500 zł',
        `Zrywanie starych powłok malarskich, 
    zrywanie starych tapet, gruntowanie ścian, 
    naprawę ewentualnych ubytków czy pęknięć,
    skuwanie starych płytek itp.`),

    createData('Wykończenie podłóg', 'od 300 zł',
        `układanie glazury i wykończenie jej poprzez fugowanie,
    układanie paneli podłogowych w systemie clik-clak, bez użycia kleju,
    układanie paneli podłogowych klejonych,
    układanie parkietu prosto i w jodełkę,
    układanie wykładziny PCV lub wykładziny dywanowej`),

    createData('Wykończenie łazienki', 'od 450 zł',
        `Montaż pojedynczego WC ze zbiornikiem zewnętrznym/podtynkowym, 
    montaż bidetu,
    montaż wanny z akrylową obudową, bez obudowy z płytek lub mozaiki,
    montaż kabiny prysznicowej.`),

    createData('Instalacje wewnętrzne', 'od 750 zł', `grzejniki, gniazdka itd.`),
    createData('Dodatkowe prace remontowe', 'od 800 zł', `Możesz chcieć zamontować okna, pomalować grzejniki, podwiesić sufit czy pomalować rury w mieszkaniu. Takie prace wyceniane są za metr bieżący lub metr kwadratowy.`),
];

export default function PricingPage() {
    return (
        <Box sx={PricingPageStyles}>
            <NavBar transitionHeight={0} disableTransparency={true} />
            <Box sx={{ flexDirection: 'column', paddingTop: '70px', width: '100%', height: '100vh', display: 'flex', alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}>
                <span className={`title highlight heading-realizations`}>Cennik</span>
                <TableContainer sx={{ width: '70%' }} component={Paper}>
                    <Table sx={{ width: '100%' }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Nazwa usługi</StyledTableCell>
                                <StyledTableCell align="right" style={{ width: '70%' }}>Opis</StyledTableCell>
                                <StyledTableCell align="right">Cena</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <StyledTableCell component="th" scope="row">
                                        {row.name}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">{row.description}</StyledTableCell>
                                    <StyledTableCell align="right">{row.price}</StyledTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box >
    );
}