import { Box, Card, CardContent, Grid } from "@mui/material";
import SquareCheckIcon from "./SquareCheckIcon";

const ServiceCard = (props) => {
    return (
        <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Card variant="outlined" sx={{ width: '100%', marginX: '40px', marginY: '20px' }}>
                    <CardContent className="overview-box__detail" sx={{ flexDirection: 'column', marginTop: '10px' }}>
                        <span className="overview-box__label">{props.title}</span>
                        <Box sx={{ display: 'flex', flexDirection: 'column', marginY: '20px', width: '100%' }}>
                            {props.text.map(el => (
                                <>
                                    <Grid container sx={{ marginY: '5px' }}>
                                        <Grid item xs={2} sx={{ marginTop: '2px', display: 'flex', justifyContent: 'center' }}>
                                            <SquareCheckIcon />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <span style={{ marginTop: '20px' }}>{el}</span>
                                        </Grid>
                                    </Grid>
                                </>

                            ))}
                        </Box>

                    </CardContent>
                </Card>
            </Box>
        </Grid >
    )
}

export default ServiceCard;