import PhotoCard from "../components/PhotoCard";
import { Grid, Box } from "@mui/material";
import { Link } from "react-router-dom";
import NavBar from '../components/NavBar';
import React, {useEffect} from "react";

const GalleryPageStyles = {
    '.title': {
        fontSize: {
            md: '3.25vw',
            lg: '2.5vw',
        }
    }
}

const GalleryItems = [{
    id: 0,

},
{
    id: 1,

},
{
    id: 2,

},
{
    id: 3,

},
{
    id: 4,

},
{
    id: 5,

}, { id: 6, }, { id: 7, }, { id: 8, },{ id: 9, },{ id: 10, },{ id: 11, }, { id: 12, }, { id: 13, }, { id: 14, }, { id: 15, }, { id: 16, }, { id: 17, }, { id: 18, }, {id: 19}];

const GalleryPage = () => {
    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);

    const scrollElement = React.useRef(null);

    const handleScroll = () => {
        scrollElement.current.scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <>
            <NavBar transitionHeight={1} />
            <Box sx={GalleryPageStyles}>
                <section>
                    <div className="header__hero">
                        <div className="header__hero-overlay-gallery"></div>
                        <img className="header__hero-img--2" src="/img/building-3.webp" alt="" />
                    </div>
                    <div className="heading-box-gallery-img">
                        <img className="heading-img" src="/img/interior-1.png" alt="" />
                    </div>
                    <div className="heading-box-gallery">
                        <h1 className="heading-tertiary">Nasze realizacje</h1>
                        <div className="heading-box__group--2">
                            <div className="heading-box__detail-gallery">
                                <span>Nasze realizacje cechują się ogromną różnorodnością. Wykańczamy wnętrza o charakterze loftowym, rustykalnym, industrialnym i wielu innych. Funkcjonalne kuchnie, klimatyczne pokoje dzienne, kameralne sypialnie. Odkryj nasze metamorfozy wnętrz i zobacz jak możemy odmienić twój dom!</span>
                            </div>
                        </div>
                    </div>

                    <div className="down-arrows" >
                        <svg className="arrows" onClick={handleScroll}>
                            <path className="a1" d="M0 0 L30 32 L60 0"></path>
                            <path className="a2" d="M0 20 L30 52 L60 20"></path>
                            <path className="a3" d="M0 40 L30 72 L60 40"></path>
                        </svg>
                    </div>

                </section>
                <Box ref={scrollElement} sx={{ backgroundColor: '#f7f7f7', padding: '70px 20px 0' }}>
                    <Grid container className="card-container">
                        {GalleryItems.map(item =>
                            <Grid item xs={12} sm={6} md={4} sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex', padding: { xs: '10px', md: '15px', lg: '30px', xl: '50px' } }} >
                                <PhotoCard id={item.id} text={item.text} />
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </Box>
        </>


    )
}

export default GalleryPage;