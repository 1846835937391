import { Box } from "@mui/material";
import NavBar from '../components/NavBar';
import ReviewCard from "../components/ReviewCard";
import ConstructionIcon from '@mui/icons-material/Construction';
import SquareCheckIcon from "../components/SquareCheckIcon";
import ContactFormDialog from "../components/ContactFormDialog";
import React from "react";

const HomePage = () => {
    const [openContactForm, setOpenContactForm] = React.useState(false);
    const handleOpenDialog = () => {
        setOpenContactForm(true);
    };
    const handleCloseDialog = () => {
        setOpenContactForm(false);
    };

    return (
        <>
            <NavBar  transitionHeight={0} disableTransparency={true} />
            <section className="section-header">
                <div className="header__hero">
                    <img className="header__hero-img" src="/img/building-4.webp" alt="" />
                </div>
                <div className="heading-box">
                    <h1 className="heading-primary">
                        <span>
                            Sztuka Architektury
                        </span>
                    </h1>
                    <div className="heading-box__group">
                        <div className="heading-box__detail">
                            {/* <svg className="heading-box__icon" xlink:href='/img/icons.svg#icon-clock'>

                            </svg> */}
                            <span>Realizacje zgodnie z planem</span>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-description">
                <div className="overview-box">
                    <div>
                        <div className="overview-box__group">
                            <h2 className="heading-secondary ma-bt-lg">O nas</h2>
                            <p className="overview-box__detail">
                                Jeśli szukasz rzetelnej ekipy do wykonania kompleksowych prac wykończeniowych, robót budowlanych w obrębie budynku, to dobrze trafiłeś.
                                Doradzimy w kwestiach technicznych, zadbamy o najwyższą jakość materiałów, dokonamy analizy stanu obecnego budynku oraz, w oparciu o Państwa wytyczne, ustalimy zakres prac by efekt końcowy spełnił pokładane nadzieje.
                                Podejmiemy się prac w lokalach mieszkalnych oraz domach jednorodzinnych w pełnym zakresie usług z tym związanych.
                                Wstępne omówienie zakresu prac i terminu odbywa się na pierwszym spotkaniu z klientem.
                            </p>
                            <div className="overview-box__group">
                                <div className="overview-box__detail">
                                    <img className="overview-box__img" src='/img/users/sebastian.webp' alt="" />
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <span className="overview-box__label">Sebastian Zdrojowy</span>
                                        <span className="overview-box__text">Kierownik robót</span>
                                    </Box>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="description-box">
                    <h2 className="heading-secondary ma-bt-lg">
                        Czym się zajmujemy?
                    </h2>
                    <p className="overview-box__detail">
                        <ul>
                            <li>
                                <Box sx={{ display: 'flex', alignContent: 'center', flexDirection: 'column' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '25px' }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <SquareCheckIcon />
                                            <span className="overview-box__label">Wykończenie wnętrz</span>
                                        </Box>
                                        <span>Oferujemy kompleksowe wykończenie wnętrz w skład którego wchodzą między innymi usługi glazurnicze, szpachlowanie, prace konstrukcyjne, instalacje grzewcze i sanitarne i wiele innych.</span>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '25px' }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <SquareCheckIcon />
                                            <span className="overview-box__label">Współpraca ze studiem projektowym</span>
                                        </Box>
                                        <span>Wykonamy wszelakie prace konstrukcyjne, zgodnie z projektem i nadzorem kierownika budowy.
                                            Doradzimy w kwestiach technicznych, iż zdajemy sobie sprawę że nie każdy musi być budowlańcem, by cieszyć się z przestronnego i dobrze zaprojektowanego wnętrza
                                        </span>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '25px' }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <SquareCheckIcon />
                                            <span className="overview-box__label">Własne projekty</span>
                                        </Box>
                                        <span>W przyszłości nasza firma zamierza również zaoferować Państwu własne projekty wykończenia wnętrz stworzone specjalnie dla Państwa mieszkania</span>
                                    </Box>
                                </Box>
                            </li>
                        </ul>
                    </p>
                    {/* -const paragraphs = tour.description.split('\n');
                    each p in paragraphs
                        p.description__text= p */}
                </div>
            </section>
            <section className="section-pictures">
                <div className="picture-box">
                    <img className="picture-box__img picture-box__img--1" src="/img/buildings/loft-1.jpg" alt="" />
                </div>
                <div className="picture-box">
                    <img className="picture-box__img picture-box__img--2" src="/img/buildings/loft-2.jpg" alt="" />
                </div>
                <div className="picture-box">
                    <img className="picture-box__img picture-box__img--3" src="/img/buildings/loft-3.jpg" alt="" />
                </div>
                {/* each img, index in tour.images
                    .picture-box
                        img.picture-box__img(src=`/img/tours/${img}`, alt=`The Park Camper Tour ${index +1}`, class=`picture-box__img--${index +1}`) */}
            </section>
            <section className="section-reviews">
                <div className="reviews">
                    <ReviewCard rating={5} username="Kamil Zaborowski" avatar="user-1" opinion="Bardzo szybka i profesjonalna robota" />
                    <ReviewCard rating={5} username="Aleksandra Małowiejska" avatar="user-2" opinion="Ekipa zadbała nawet o najmniejsze detale. Jestem bardzo zadowolona" />
                    <ReviewCard rating={4} username="Wojciech Balak" avatar="user-17" opinion="Firma z polecenia i zdecydowanie się sprawdziła" />
                    <ReviewCard rating={5} username="Krzysztof Lipiński" avatar="user-13" opinion="Super robota" />
                    <ReviewCard rating={4} username="Blanka Nowicka" avatar="user-4" opinion="Nie mam nic do zarzucenia. Wszystko okey." />
                    <ReviewCard rating={5} username="Bartłomiej Nowiński" avatar="user-10" opinion="Super, że udało się dotrzymać terminów. Bardzo na plus" />
                    <ReviewCard rating={5} username="Krystyna Sienkiewicz" avatar="user-20" opinion="Kontakt z polecenia znajomego. Zdeycdowanie było warto." />
                </div>
                {/* <ScrollableGallery /> */}
            </section>
            <section className="section-cta">
                <div className="cta">
                    <div className="cta__img cta__img--logo">
                        <ConstructionIcon sx={{ fontSize: 90, color: 'white' }} />
                        {/* <img src='/img/logo-white.png' alt='Natours logo' /> */}
                    </div>
                    <img className="cta__img cta__img--1" src='/img/buildings/loft-2.jpg' alt='' />
                    <img className="cta__img cta__img--2" src='/img/buildings/loft-3.jpg' alt='' />
                    <div className="cta__content">
                        <h2 className="heading-secondary">Zapraszamy do kontaktu</h2>
                        <p className="cta__text">Wykończenie z pasją. Zawsze na czas.</p>
                        <button onClick={handleOpenDialog} className="btn btn--green span-all-rows" id="book-tour" data-tour-id="!">
                            Umów rozmowę!
                        </button>
                        <ContactFormDialog open={openContactForm} handleClose={handleCloseDialog} />
                    </div>
                </div>
            </section>
        </>
    )
}
export default HomePage;