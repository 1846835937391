import ContactForm from "../components/ContactForm"
import Button from "@mui/material/Button";
import CallIcon from '@mui/icons-material/Call';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import SquareCheckIcon from "../components/SquareCheckIcon";
import NavBar from '../components/NavBar'
import { Box, Card, CardContent, Typography, CardActions, Grid } from "@mui/material";
import ServiceCard from "../components/ServiceCard";

const ServicesPage = () => {
    const items = [{
        title: 'Szpachlowanie',
        text: [
            'Gładkie i proste ściany są wyznacznikiem poziomu prac, lecz nie tylko efekt wizualny ma znaczenie, warto zwrócić uwagę na samo przygotowanie powierzchni zastosowanie odpowiednich środków gruntujących, w trudnych przypadkach warstwy zbrojącej, pod ostateczną gładz. Dopiero na tak powierzchnię możemy gruntować i nakładać ostateczna farbę, bądź wymaganą strukturę.']
    },
    {
        title: 'Glazurnictwo',
        text: [
            'Montujemy płytki dużych jak i małych formatów, posiadamy narzędzia do profesjonalnej obróbki, używamy sprawdzonych klei i najważniejsze trzymamy się zaleceń producenta, bo obecnie gresy wielko gabarytowe wraz z ogrzewaniem podłogowym wymagają zastosowania odpowiedniej serii produktów.',
            'Możliwość wycięcia łuków i trudnych kształtów z płytek oraz kamienia.',
            'Fachowe przygotowanie podłoża'
        ]
    },
    {
        title: 'Roboty konstrukcyjne',
        text: [
            'Wykonamy wszelakie prace konstrukcyjne, zgodnie z projektem i nadzorem kierownika budowy',
            'Prace nie podlegające zgłoszeniu wykonujemy po omówieniu z inwestorem',
            'Doradzimy w kwestiach technicznych, iż zdajemy sobie sprawę że nie każdy musi być budowlańcem, by cieszyć się z przestronnego i dobrze zaprojektowanego wnętrza',
            'Do prac powyższych możemy zaliczyć wszelakie zmiany w obrębie ścian, drzwi, wysokości podłogi, ocieplenia wewnątrz budynku, wyciszenia pomieszczeń, likwidacji mostków termicznych'
        ]
    },
    {
        title: 'Instalacje elektryczne',
        text: [
            'Przy generalnym remoncie warto zastanowić się nad funkcjonalnym umieszczeniem łączników, oraz gniazd by maksymalnie podnieść komfort użytkowania lokalu',
            'Warto też zadbać o kwestie bezpieczeństwa użytkowania, co wynika z odpowiedniego uziemienia instalacji i zachowania bezpiecznych odległości pomiędzy prądem a wodą',
            'Możemy wprowadzić dogodny system inteligentnej instalacji'
        ]
    },
    {
        title: 'Instalacje sanitarne',
        text: [
            'Modernizacja podejść prysznicowych, wannowych, oraz innych',
            'Wymiana odpływów kanalizacyjnych',
            'Optymalizacja poprzez montaż głowic termostatycznych',
            'Dobór ogrzewania płaszczyznowego w łazienkach, w zależności od wytrzymałości konstrukcyjnej i komfortu obsługi',
            'Montaż ceramiki łazienkowej, oraz wyposażenia dodatkowego'
        ]
    },
    {
        title: 'Prace dodatkowe',
        text: [
            'Montaż parapetów wewnętrznych',
            'Przewierty w betonie, jak i betonie zbrojonym',
            'Kotwienie przy użyciu chemii budowlanej',
            'Przygotowanie pod instalację klimatyzacji, wyprowadzanie',
            'Montaż suchej zabudowy'
        ]
    }]

    const generateServiceCards = () => {
        const serviceCards = items.map(el =>
            <ServiceCard title={el.title} text={el.text} />
        );
        return serviceCards;
    }

    return (
        <div style={{ width: '100%' }}>
            <NavBar transitionHeight={0} disableTransparency={true} />
            <h2 className="contact-heading" style={{ marginTop: '140px', marginBottom: '20px', textAlign: 'center' }}>Czym się zajmujemy ?</h2>
            <Grid container>
                {generateServiceCards()}
            </Grid>
        </div>
    )
}

export default ServicesPage;