import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import ConstructionIcon from '@mui/icons-material/Construction';
import { Box, Grid } from '@mui/material';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';

export default function ContactForm(props) {
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm(process.env.REACT_APP_EMAIL_SERVICE_ID,
            process.env.REACT_APP_EMAIL_TEMPLATE_ID,
            e.target, process.env.REACT_APP_EMAIL_PUBLIC_KEY).then(result => {
                console.log(result.text)
                toast.success('Email został wysłany')
            }, 
            error => {
                console.log(error.text)
                toast.error('Nie udało się wysłać maila')
            })
    }

    return (
        <Grid container>
            <Grid xs={12} sm={6}  sx={{ paddingX: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <DialogTitle style={{ fontWeight: 600, textAlign: 'center', width: '100%', fontSize: '1.5rem' }} className='heading-secondary'>Skontaktuj się z nami</DialogTitle>
                <div style={{ fontSize: '1.5rem', fontWeight: 400, textAlign: 'center' }}>
                    Wypełnij informacje w formularzu.<br /> Odpowiemy najszybciej jak to możliwe!
                </div>
                <div style={{ width: '100%', marginTop: '25px' }}>
                    <div style={{ marginLeft: 'auto', marginRight: 'auto' }} className="cta__img--contact-form  cta__img--logo-contact-form">
                        <ConstructionIcon sx={{ fontSize: 40, color: 'white' }} />
                    </div>
                </div>
            </Grid>
            <Grid xs={12} sm={6} sx={{ paddingX: '10px' }}>
                <form sx={{ width: '100%' }} onSubmit={sendEmail}>
                    <TextField
                        name="client_email"
                        color="info"
                        margin="normal"
                        id="name"
                        label="Twój adres mailowy"
                        type="email"
                        fullWidth
                        variant="standard"
                        InputProps={{ style: { fontSize: 14 } }}
                        InputLabelProps={{ style: { fontSize: 14 } }}
                    />
                    <TextField
                        name="client_name"
                        color="info"
                        margin="normal"
                        id="name"
                        label="Imię i nazwisko"
                        type="text"
                        fullWidth
                        variant="standard"
                        InputProps={{ style: { fontSize: 14 } }}
                        InputLabelProps={{ style: { fontSize: 14 } }}
                    />
                    <TextField
                        label="Preferowana godzina kontaktu"
                        name="contact_time"
                        fullWidth
                        color="info"
                        margin="normal"
                        variant="standard"
                        type="text"
                        InputProps={{ style: { fontSize: 14 } }}
                        InputLabelProps={{ style: { fontSize: 14 } }}
                    />
                    <TextField
                        name="client_tel_number"
                        color="info"
                        margin="normal"
                        id="name"
                        label="Numer telefonu"
                        type="tel"
                        fullWidth
                        variant="standard"
                        InputProps={{ style: { fontSize: 14 } }}
                        InputLabelProps={{ style: { fontSize: 14 } }}
                    />
                    <TextField
                        name="additional_notes"
                        color="info"
                        margin="normal"
                        id="name"
                        label="Zakres prac / Dodatkowa notatka"
                        type="text"
                        fullWidth
                        multiline
                        variant="standard"
                        InputProps={{ style: { fontSize: 14 } }}
                        InputLabelProps={{ style: { fontSize: 14 } }}
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: '20px' }}>
                        <Button onClick={props.handleClose} sx={{ color: 'white', marginRight: '10px', width: '10%' }} variant="contained" color="primary">Powrót</Button>
                        <Button value="Send" type="submit" sx={{ color: 'white', width: '10%' }} variant="contained" color="secondary">Wyślij</Button>
                    </Box>
                </form>
            </Grid>
        </Grid>)
}