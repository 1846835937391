import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ConstructionIcon from '@mui/icons-material/Construction';
import IconButton from '@mui/material/IconButton';
import TimePicker from '@mui/lab/TimePicker';
import { Box, Grid } from '@mui/material';
import ContactForm from './ContactForm';
import moment from 'moment';

export default function ContactFormDialog({ handleClose, open }) {

    return (
        <div>
            <Dialog maxWidth={'md'} open={open} onClose={handleClose}>
                <DialogContent sx={{ display: 'flex', flexDirection: 'row', paddingBottom: '15px' }} >
                    <ContactForm handleClose={handleClose} />
                </DialogContent>
            </Dialog>
        </div>
    );
}