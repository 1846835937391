import * as React from 'react';
import Card from '@mui/material/Card';
import { Box } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const PhotoCardStyles = {
  '.photoCardContainer': {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '3px',
  },
  '.image': {
    lg: {
      height: '29em',
      width: '37em',
      'object-fit': 'cover',
    },
    md: {
      height: '20em', 
      width: '28em',
      'object-fit': 'cover',
    },
    sm: {
      height: '17em', 
      width: '25em',
      'object-fit': 'cover',
    },
    xs: {
      height: '22em', 
      width: '30em',
      'object-fit': 'cover',
    },
  },
  'img': {
    opacity: 1,
    transition: '.5s ease',
    backfaceVisibility: 'hidden',
  },
  '.photoCardContainer:hover': {
    cursor: 'pointer',
  },
  '.photoCardContainer:hover .overlay': {
    opacity: 0.6
  },
  '.photoCardContainer:hover .image': {
    transform: 'scale(1.2)',
  },
  '.photoCardContainer:hover .middle': {
    opacity: 1,
  },
  '.text': {
    color: 'white',
    fontSize: '16px',
    padding: '16px 32px',
  },
  '.overlay': {
    transition: '.5s ease',
    opacity: 0,
    zIndex: 50,
    backgroundColor: '#000000',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    '-ms-transform': 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
  },
  '.middle': {
    transition: '.5s ease',
    opacity: 0,
    position: 'absolute',
    zIndex: 100,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    '-ms-transform': 'translate(-50%, -50%)',
    textAlign: 'center',
  }
}

export default function PhotoCard(props) {
  return (
    <Box sx={PhotoCardStyles}>
      <div>
        <img
        className='image'
          src={`/img/gallery/${props.id}.webp`}
          alt="loft flat"
        />
      </div>
    </Box>
  );
}