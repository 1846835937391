import logo from './logo.svg';
import { Box, ThemeProvider } from '@mui/material';
import theme from './theme/index.js';
import HomePage from './pages/HomePage';
import NavBar from './components/NavBar';
import GalleryPage from './pages/GalleryPage';
import GalleryDetailsPage from './pages/GalleryDetailsPage';
import PricingPage from './pages/PricingPage';
import ContactPage from './pages/ContactPage';
import DateAdapter from '@mui/lab/AdapterMoment';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { LocalizationProvider } from '@mui/lab';
import ServicesPage from './pages/ServicesPage';
import React from 'react';


function App() {
  return (
    <ThemeProvider theme={theme}>
      <ToastContainer position="bottom-right" style={{fontSize: '16px'}} />
      <LocalizationProvider dateAdapter={DateAdapter}>
        <ThemeProvider theme={theme}>
          <Router>
            <Switch>
              <Route path="/" exact>
                <HomePage />
              </Route>
              <Route path="/gallery" exact>
                <GalleryPage />
              </Route>
              <Route path="/gallery/:id" exact>
                <GalleryDetailsPage />
              </Route>
              <Route path="/pricing" exact>
                <PricingPage />
              </Route>
              <Route path="/contact" exact>
                <ContactPage />
              </Route>
              <Route path="/services" exact>
                <ServicesPage />
              </Route>
            </Switch>
          </Router>
        </ThemeProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
