import PhotoCard from "../components/PhotoCard";
import { Grid, Box } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import NavBar from '../components/NavBar';
import React from "react";
import { useParams } from "react-router-dom";

const GalleryDetailsPageStyles = {
    '.photoCardContainer': {
        width: {
            xs: '70%',
            md: '60%',
        },
        height: {
            xs: '65%',
            md: '80%',
            lg: '100%',
        },
        overflow: 'hidden',
        borderRadius: '3px',
        '-webkit-box-shadow': '0 3rem 8rem 0.5rem rgba(0, 0, 0, 0.15)',
        boxShadow: '0 3rem 8rem 0.5rem rgba(0, 0, 0, 0.15)',
    },
    '.image': {
        opacity: 1,
        display: 'block',
        width: '100%',
        height: '100%',
        '-o-object-fit': 'cover',
        objectFit: 'cover',
        '-o-object-position': '50% 100%',
        objectPosition: '50% 100%',
        marginX: 'auto',
    },
    '.title': {
        position: 'absolute',
        transform: 'translateY(-50%)',
        width: '100%',
        textAlign: 'center',
        top: {
            xs: '18%',
        },
    },
    '.slider': {
        width: '100%',
        height: '100%',
        margin: 0,
        position: 'relative',
        top: {
            xs: '15%',
            sm: '20%',
            md: '25%',
        },
        /* IN THE END */
        overflow: 'hidden',
    },
    '.slide': {
        position: 'absolute',
        width: '100%',
        height: '45rem',
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'center',
        justifyContent: 'center',

        /* THIS creates the animation! */
        transition: 'transform 1s',
    },
    '.dots': {
        position: 'absolute',
        bottom: {
            xs: '40%',
            sm: '37%',
            md: '35%',
            lg: '30%',
        },
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'flex',
    }
}

const GalleryItems = [{
    id: 0,
    text: 'Industrialne wnętrzne w ',
    highlight: 'Warszawie',
},
{
    id: 1,
    text: 'Nowoczesny apartament w ',
    highlight: 'Zabrzu'
},
{
    id: 2,
    text: 'Loftowe mieszkanie w ',
    highlight: 'Poznaniu'
},
{
    id: 3,
    text: 'Rustykalna kawalerka w sercu ',
    highlight: 'Gdańska'
},
{
    id: 4,
    text: 'Dom z klimatem prosto z Bieszczad',
},
{
    id: 5,
    text: 'Loftowe mieszkanie w ',
    highlight: 'Poznaniu'
}];

const GalleryDetailsPage = (props) => {
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const params = useParams();

    const nextSlide = function () {
        const slides = document.querySelectorAll('.slide');
        const maxSlide = slides.length;
        if (currentSlide === maxSlide - 1) {
            setCurrentSlide(0);
        } else {
            setCurrentSlide(currentSlide + 1);
        }

    };

    const prevSlide = function () {
        const slides = document.querySelectorAll('.slide');
        const maxSlide = slides.length;
        if (currentSlide === 0) {
            console.log(maxSlide);
            setCurrentSlide(maxSlide - 1);
        } else {
            setCurrentSlide(currentSlide - 1);
        }
    };

    const handleDotClick = (dotIndex) => {
        setCurrentSlide(dotIndex);
    }

    return (
        <Box sx={GalleryDetailsPageStyles}>
            <NavBar transitionHeight={0} disableTransparency={true} />
            <section className="section" id="section--3">
                <div className="sliderContainer">

                    <Box className="title">
                        <h2 className="heading-realizations">{GalleryItems[params.id].text}
                            <span className="highlight">{GalleryItems[params.id].highlight}</span>
                        </h2>
                    </Box>
                    <div className="slider">
                        <div className="slide" style={{ transform: `translateX(${100 * (-currentSlide)}%)` }}>
                            <div className="photoCardContainer">
                                <img
                                    className='image'
                                    src="/img/buildings/loft-1.jpg"
                                    alt="loft flat"
                                />
                            </div>
                        </div>

                        <div className="slide" style={{ transform: `translateX(${100 * (1 - currentSlide)}%)` }}>
                            <div className="photoCardContainer">
                                <img
                                    className='image'
                                    src="/img/buildings/loft-2.jpg"
                                    alt="loft flat"
                                />
                            </div>
                        </div>

                        <div className="slide" style={{ transform: `translateX(${100 * (2 - currentSlide)}%)` }}>
                            <div className="photoCardContainer">
                                <img
                                    className='image'
                                    src="/img/buildings/loft-3.jpg"
                                    alt="loft flat"
                                />
                            </div>
                        </div>

                        <button onClick={prevSlide} className="slider__btn slider__btn--left">&larr;</button>
                        <button onClick={nextSlide} className="slider__btn slider__btn--right">&rarr;</button>
                        <div className="dots">
                            <button onClick={() => handleDotClick(0)} className={`dots__dot ${currentSlide === 0 ? 'dots__dot--active' : ''}`}></button>
                            <button onClick={() => handleDotClick(1)} className={`dots__dot ${currentSlide === 1 ? 'dots__dot--active' : ''}`}></button>
                            <button onClick={() => handleDotClick(2)} className={`dots__dot ${currentSlide === 2 ? 'dots__dot--active' : ''}`}></button>
                        </div>
                    </div>
                </div>
            </section>
        </Box>


    )
}

export default GalleryDetailsPage;