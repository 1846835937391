import ContactForm from "../components/ContactForm"
import Button from "@mui/material/Button";
import CallIcon from '@mui/icons-material/Call';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import NavBar from '../components/NavBar'
import { Box } from "@mui/material"

const ContactPage = () => {

    return (
        <div style={{ width: '100%' }}>
            <NavBar transitionHeight={0} disableTransparency={true} />
            <h2 className="contact-heading" style={{ marginTop: '140px', marginBottom: '20px', textAlign: 'center' }}>RemDom ,,Sebastian Zdrojowy"</h2>
            <Box container sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ width: '90%', display: 'flex', flexDirection: 'column' }}>
                    <ContactForm />
                </Box>
            </Box>
            <Box container sx={{ marginTop: '45px', paddingLeft: '1rem', paddingRight: '1rem', display: 'flex', flexWrap: 'wrap', width: '100%', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }} className="overview-box__label">
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '30px', marginRight: '20px' }}>
                    <CallIcon sx={{ fontSize: '40px' }} />
                    <span style={{ marginLeft: '10px' }}>602 120 254</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '30px', marginRight: '20px' }}>
                    <LocationOnIcon sx={{ fontSize: '40px' }} />
                    <span style={{ marginLeft: '10px' }}>Poznań, ul. Łużycka 74A</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '30px', marginRight: '20px' }}>
                    <ClearAllIcon sx={{ fontSize: '40px' }} />
                    <span style={{ marginLeft: '10px' }}>NIP: 972-132-7368</span>
                </div>
            </Box>
        </div>
    )
}

export default ContactPage;